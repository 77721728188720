<script setup>
const { t } = useI18n({})

const { data: products } = await useApi('/api/products/featured', { watch: false })
</script>

<template>
  <div
    v-if="products && products.length"
    class="container mx-auto mb-40 w-screen px-2"
  >
    <div class="flex-center mt-40 flex flex-col">
      <div class="title mb-4 text-center text-2xl font-bold">
        {{ t('home.product_spotlight') }}
      </div>

      <div v-auto-animate class="grid md:grid-cols-2 lg:grid-cols-3 w-full gap-4">
        <ProductItem
          v-for="product in products"
          :key="product.id"
          :product="product"
        />
      </div>
    </div>
  </div>
</template>
