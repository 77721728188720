<!-- eslint-disable prefer-const -->
<script setup>
(function () {
  let i
  let e
  const d = document
  const s = 'script'
  i = d.createElement('script')
  i.async = 1
  i.charset = 'UTF-8'
  i.src = 'https://cdn.curator.io/published/b0dab2c7-0d80-4022-9276-716e0b837f27.js'
  e = d.getElementsByTagName(s)[0]
  e.parentNode.insertBefore(i, e)
})()
</script>

<template>
  <div id="curator-feed-default-feed-layout">
    <a href="https://curator.io" target="_blank" class="crt-logo crt-tag">Powered by Curator.io</a>
  </div>
</template>
