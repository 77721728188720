<script setup>
const { t } = useI18n({})
</script>

<template>
  <div class="container mx-auto relative z-20 flex flex-col items-center gap-64 px-2">
    <div
      class="px-2 text-center text-2xl max-w-xl"
      v-html="t('home.middle_text')"
    />
    <OurProducts class=" px-2" />
  </div>
</template>
