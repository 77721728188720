<script setup>
const { t } = useI18n({})

const { data: adviceBlogItems } = await useApi('/api/blogs', {
  watch: false,
  params: {
    type: 'advice',
  },
})
</script>

<template>
  <div class="relative">
    <div
      v-if="adviceBlogItems && adviceBlogItems.data.length"
      class="container mx-auto mb-40 w-screen px-2"
    >
      <div class="flex-center mt-40 flex flex-col">
        <div class="title mb-4 text-center text-2xl font-bold">
          {{ t('home.experts') }}
        </div>

        <div class="max-w-screen -mx-2 flex flex-row flex-wrap justify-center">
          <AdviceItem v-for="item in adviceBlogItems.data.slice(0, 3)" :key="`${item.title}-advice`" :item="item" />
        </div>
      </div>
    </div>
    <Curl1 />
  </div>
</template>
