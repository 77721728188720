<script setup>
import { useElementVisibility } from '@vueuse/core'

const { t, locale } = useI18n()

const target = ref(null)

const isVisible = useElementVisibility(target, {
  threshold: 0.5,
})

watch(isVisible, (value) => {
  if (value) {
    document.querySelector('#laststroke').classList.remove('hidden')
    document.querySelector('#laststroke').classList.add('path')
  }
})
</script>

<template>
  <div ref="target" />
  <div class="pointer-events-none absolute -right-20 top-1/2 z-0 h-screen overflow-hidden" style="height: 120%">
    <div class=" h-full">
      <svg height="100%" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <defs>
          <linearGradient id="linearGradient-1" x1="66.0705874%" x2="66.0705874%" y1="4.00688724%" y2="55.2654083%">
            <stop offset="0%" stop-color="#DC7818" stop-opacity="0.214802775" />
            <stop offset="100%" stop-color="#DC7818" />
          </linearGradient>
        </defs>
        <g fill="none" fill-rule="evenodd" stroke="none" stroke-width="1">
          <g id="Home-" stroke="#DC7818" stroke-width="2" transform="translate(-1072.000000, -4049.000000)">
            <g id="Group-29" transform="translate(0.000000, 4026.676815)">
              <path
                id="laststroke" class="hidden"
                d="M1456.56392,0 C1456.56392,0 1271.96642,267.834678 1349.47609,297.430687 C1409.80223,320.4654 1431.12564,237.149588 1379.84913,200.697845 C1370.41303,193.98938 1355.46332,185.53116 1335,175.323185 C1175.64217,93.9124727 1088.80884,89.0791394 1074.5,160.823185 C1053.4501,266.364097 1383.56833,627.465284 1445,654.823185"
              />
            </g>
          </g>
        </g>
      </svg>
    </div>
  </div>
</template>

<style scoped>
.path {
  stroke-dasharray: 3000;
  stroke-dashoffset: 3000;
  animation: dash 5s linear forwards;
}

.title-font {
  font-family: interstate, sans-serif;
  font-style: normal;
}

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}
</style>
