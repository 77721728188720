<script setup>
import { useI18n } from 'vue-i18n'
import ProductCard from './ProductCard.vue'
import gardenImage from '~/assets/images/home/garden_image.webp'
import terrasImage from '~/assets/images/home/terras_image.webp'
import decoImage from '~/assets/images/home/deco_image.webp'
import PaintButton from '@/components/general/PaintButton.vue'

const { t } = useI18n({})

const productCards = ref(
  [
    {
      id: 1,
      title: t('home.garden'),
      text: t('home.garden_text'),
      tags: [t('home.garden_tag_1'), t('home.garden_tag_2')],
      image: gardenImage,
      link: '/products/garden/all',
    },
    {
      id: 2,
      title: t('home.terras'),
      text: t('home.terras_text'),
      tags: [t('home.terras_tag_1'), t('home.terras_tag_2')],
      image: terrasImage,
      link: '/products/balcony/all',
    },
    {
      id: 3,
      title: t('home.deco'),
      text: t('home.deco_text'),
      tags: [t('home.deco_tag_1'), t('home.deco_tag_2')],
      image: decoImage,
      link: '/products/deco/all',
    },
  ],
)
</script>

<template>
  <div class="flex flex-col items-center gap-12">
    <div class="text-center text-2xl font-bold">
      {{ t('home.our_products') }}
    </div>
    <div class="flex flex-col gap-8 md:flex-row 2xl:w-2/3">
      <div
        v-for="productCard in productCards"
        :key="productCard.id"
        class="md:w-1/3"
      >
        <ProductCard
          :card-data="productCard"
        />
        <div class="mt-3 max-w-max">
          <NuxtLinkLocale
            :to="productCard.link"
          >
            <PaintButton :button-text="t('home.discover_products')" />
          </NuxtLinkLocale>
        </div>
      </div>
    </div>
  </div>
</template>
