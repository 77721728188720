<script setup>
import { useElementVisibility } from '@vueuse/core'

const { t, locale } = useI18n()

const target = ref(null)

const isVisible = useElementVisibility(target, {
  threshold: 0.5,
})

watch(isVisible, (value) => {
  if (value) {
    document.querySelector('#path').classList.remove('hidden')
    document.querySelector('#path').classList.add('path')
  }
})
</script>

<template>
  <div ref="target" class="relative">
    <div
      class="absolute -left-20 -top-40 z-0 h-screen"
      style="height: 120%"
    >
      <div class="h-full w-full">
        <svg
          height="100%"
          version="1.1"
          viewBox="0 0 921 1178"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
        >
          <title>Stroke 1</title>
          <desc>Created with Sketch.</desc>
          <g
            fill="none"
            fill-rule="evenodd"
            stroke="none"
            stroke-width="1"
          >
            <g
              id="Home-"
              stroke="#DC7818"
              stroke-width="2"
              transform="translate(0.000000, -2144.000000)"
            >
              <path
                id="path"
                class="hidden"
                d="M592.323008,3321 C400.920937,3059.07154 255.981174,3012.70356 159.091262,3018.63905 C53.2331599,3025.1227 23.2086775,3092.90114 -107.167342,3099.87067 C-237.99542,3106.86155 -419.48603,3049.92894 -493.014866,2899.04835 C-543.892749,2794.64787 -548.945476,2633.23646 -454.655787,2549.30317 C-445.908287,2541.51496 -378.409264,2483.27957 -289.937597,2495.14876 C-113.659892,2518.79816 -97.581577,2788.09302 62.0643088,2806.53486 C154.469236,2817.20983 265.771612,2740.46854 280.936912,2655.35527 C291.053045,2598.57927 256.803422,2546.99483 249.348024,2535.76457 C241.458366,2523.88114 210.303739,2479.85354 118.475452,2445.50781 C-20.4469554,2393.54605 -99.0925898,2438.22505 -131.987818,2389.09666 C-157.360019,2351.20566 -135.864127,2286.8657 -104.910612,2251.45568 C-37.8511886,2174.73752 96.5097048,2207.5936 127.046757,2215.06145 C220.854132,2238.00073 222.970262,2279.28394 291.311109,2285.59318 C392.636173,2294.94758 520.814195,2216.455 537.243122,2108.33483 C538.197071,2102.05763 549.082058,2021.8992 493.624863,1969.12766 C435.469559,1913.78971 338.319802,1920.88738 280.173397,1958.91898 C184.751783,2021.33502 185.0668,2173.16422 226.346459,2268.88839 C228.763367,2274.48928 274.798534,2377.42858 372.978769,2418.30246 C524.165475,2481.24531 750.794255,2376.94449 919.604104,2147.51793"
                transform="translate(195.302052, 2625.000000) scale(-1, -1) translate(-195.302052, -2625.000000) "
              />
            </g>
          </g>
        </svg>
      </div>
    </div>
    <div class="flex-center relative mt-64 flex h-full flex-col gap-20 text-center md:flex-row md:text-left  ">
      <div class="absolute top-0 -z-10 h-full overflow-hidden w-screen bg-pattern" />

      <div class="relative order-2 w-full px-10 md:order-1 md:w-1/2 md:py-20 2xl:p-40">
        <img
          class="w-full"
          src="~/assets/images/home/buddy2.svg"
          alt=""
        >
        <div class="shadow-input absolute top-0 right-4 rounded-full rounded-bl bg-white px-4 py-2 md:top-40 md:right-40">
          {{ t('gio.welcome') }}
        </div>
        <div class="shadow-input absolute top-12 right-4 rounded-full bg-white px-4 py-2 md:top-52 md:right-40">
          {{ t('gio.help') }}
        </div>
      </div>
      <div class="order-1 flex max-w-4xl flex-col items-center gap-12 py-12 px-10 text-white md:order-2 md:w-1/2 md:items-start md:py-20 2xl:p-40 2xl:pl-10">
        <div
          class="subtitle text-2xl font-bold"
          v-html="t('home.gino_subtitle')"
        />
        <div
          class="title title-font text-5xl font-bold"
          v-html="t('home.gino_title')"
        />
        <div
          class="text text-lg"
          v-html="t('home.gino_text')"
        />
        <div class="button">
          <div class="max-w-max">
            <a
              :href="`https://tool.giardino-online.com/?language=${locale}`"
              target="_blank"
            >
              <PaintButton
                :button-text="t('home.gino_button')"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.path {
  stroke-dasharray: 8000;
  stroke-dashoffset: 8000;
  animation: dash 5s linear forwards;
}

.title-font {
  font-family: interstate, sans-serif;
  font-style: normal;
 }

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}
</style>
